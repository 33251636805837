/** ------------------------------
    Fonts
    ------------------------------ */

@font-face {
    font-family: 'FS Lucas';
    font-weight: 300;
    src: url('../resources/assets/fonts/fs-lucas-light.woff2') format('woff2');
}

@font-face {
    font-family: 'FS Lucas';
    font-weight: 400;
    src: url('../resources/assets/fonts/fs-lucas-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'FS Lucas';
    font-weight: 500;
    src: url('../resources/assets/fonts/fs-lucas-medium.woff2') format('woff2');
}

@font-face {
    font-family: 'FS Lucas';
    font-weight: 600;
    src: url('../resources/assets/fonts/fs-lucas-semibold.woff2') format('woff2');
}

@font-face {
    font-family: 'FS Lucas';
    font-weight: 700;
    src: url('../resources/assets/fonts/fs-lucas-bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Barlow';
    font-weight: 200;
    src: url('../resources/assets/fonts/barlow-thin.woff2') format('woff2');
}

@font-face {
    font-family: 'Barlow';
    font-weight: 400;
    src: url('../resources/assets/fonts/barlow-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Barlow';
    font-weight: 500;
    src: url('../resources/assets/fonts/barlow-medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Barlow';
    font-weight: 700;
    src: url('../resources/assets/fonts/barlow-bold.woff2') format('woff2');
}

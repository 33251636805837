/** ------------------------------
    Social List
    ------------------------------ */
.social-list {
    @mixin list-reset;
    display: flex;
    align-items: center;
    }
    .social-list__item {

        &:not(:first-child) {
            margin-left: 1.5em;
            }

        & a {
            display: block;
            line-height: 1;
            }

        & svg {
            width: 1.5rem;
            height: 1.5rem;
            }
        }

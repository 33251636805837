/** ------------------------------
    Cookie consent customisation
    ------------------------------ */
.cc-window {
    font-family: var(--font-sans);
    font-size: .925rem;

    & p {
        margin-bottom: 1em;
        }
    }
    .cc-btn {
        font-size: 1rem;
        text-decoration: none;
        }

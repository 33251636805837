/** ------------------------------
    Card
    ------------------------------ */
.card {
    overflow: hidden; /* Remove psuedo link leaking out */
    position: relative;

    & a {
        color: currentcolor;
        text-decoration: none;
        }

    &:matches(:hover, :focus-within) {

        /* Scale image */
        & .card__media img {
            transform: scale(1.06);
            }

        /* Shift CTA arrow */
        & .card__cta {
            color: var(--brand-primary);

            & > svg {
                transform: translateX(3px);
                }
            }

        }
    }
    .card__media {
        overflow: hidden; /* Prevent image transition leaking out */

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform-origin: 50% 50%;
            transition: transform .5s ease-out;
            }
        }
    .card__body {
        padding-top: 1.25rem;
        }
        .card__title {
            font-size: 2rem;
            font-weight: 500;
            line-height: 1.25;
            color: var(--brand-secondary);

            & a {
                /* Pseduo link covering entire card */
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 999;
                    }
                }
            }
        .card__tag {
            margin-bottom: 1rem;
            color: var(--brand-primary);
            }
        .card__cta {
            display: inline-flex;
            align-items: center;
            font-family: var(--brand-font);
            font-weight: 500;
            color: var(--brand-secondary);
            transition: color .3s ease;

            & > svg {
                margin-left: .5rem;
                width: 1rem;
                height: 1rem;
                transition: transform .3s ease,
                            fill .3s ease;
                }
            }


/** ------------------------------
    Modifiers
    ------------------------------ */
.card--row {

    @media (width > 800px) {
        display: flex;

        & .card__media {
            max-width: 420px;
            flex: 0 0 45%;
            }
        & .card__body {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            }
        }

    }


.card--featured {

    @media (width > 800px) {

        & .card__media {
            position: relative;
            padding-bottom: calc(9 / 16 * 100%);

            & > * {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                }
            }

        & .card__body {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 2.5rem;
            width: 60%;
            max-width: 660px;
            background-color: #fff;
            }
            & .card__title {
                font-size: 2.5rem;
                line-height: 1;
                max-width: 500px;

                /* Relocate psuedo link, ensuring it covers the card */
                & a::after {
                    top: auto;
                    left: auto;
                    bottom: 0;
                    right: 0;
                    width: 100vw;
                    height: calc(9 / 16 * 100vw);
                    }
                }
        }

    }


/** ------------------------------
    Card grid
    ------------------------------ */
.card-grid {
    }
    .card-grid__item {
        margin-top: var(--grid-gutter);
        }

@media (width >= 600px) {

    .card-grid {
        @mixin grid-container;
        display: flex;
        flex-wrap: wrap;
        }
        .card-grid__item {
            @mixin grid-item;
            flex: 0 0 50%;
            }

}

@media (width >= 1000px) {

    .card-grid__item {
        flex-basis: 25%;
        }

}


/** ------------------------------
    Card stack
    ------------------------------ */
.card-stack {
    }
    .card-stack__item {

        &:not(:first-child) {
            margin-top: var(--grid-gutter);
            }

        }


/** ------------------------------
    Card filters
    ------------------------------ */
.card-filters {
    margin: 4rem 0;
    }

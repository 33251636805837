/** ------------------------------
    Team carousel
    ------------------------------ */
.team-carousel {
    margin: 4rem 0 2.5rem;

    & .glide--swipeable {
        cursor: grab;
        }

    & .glide--swipeable {
        cursor: grabbing;
        }

    & [data-glide-el="track"] {
        width: 125vw; /* Show upcoming slides */
        }

    /* Fade out as default state */
    & .glide__slide {
        background-color: #2b3245;
        pointer-events: none;

        & .team-card {
            opacity: .4;
            transition: opacity .3s ease;
            }
        }

    /* Remove default state from active slide and next two siblings */
    & :matches(.glide__slide--active, .glide__slide--active + *, .glide__slide--active + * + *) {
        pointer-events: auto;

        & .team-card {
            opacity: 1;
            }
        }
    }
    .team-carousel__nav {
        display: flex;

        & [data-glide-el="controls"] {
            display: flex;
            justify-content: space-between;
            margin-left: 2rem;
            width: 80px;
            height: 1.5rem;
            transform: translateY(-50%);

            & button {
                @mixin button-reset;
                color: #fff;
                transition: color .3s ease;
                cursor: pointer;

                &:focus {
                    outline: 0;
                    }

                &:hover {
                    color: var(--brand-primary);
                    }
                }
            }
        }
        .team-carousel__nav-progress {
            flex-grow: 1;
            position: relative;
            padding-top: 1rem;
            border-top: 1px solid rgba(255, 255, 255, .2);
            }
            .team-carousel__nav-thumb {
                position: absolute;
                top: -2px;
                left: 0;
                height: 3px;
                background-color: var(--brand-primary);
                width: 0%;
                transition: width .3s ease-out;
                }
            .team-carousel__nav-count {
                min-height: 1em; /* Set a height, removing shift when digits are added to HTML */
                font-size: .875rem;
                font-weight: 700;
                line-height: 1.2;
                letter-spacing: .3em;
                }

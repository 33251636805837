/** ------------------------------
    Article
    ------------------------------ */
.article {
    }

    /* Header */
    .article__header-container {
        background-color: var(--brand-secondary);
        color: #fff;
        }
        .article__header {
            @mixin container;
            @mixin container-gutters;
            margin-top: 2.5rem;
            }
            .article__header-media {

                @media (width <= 860px) {
                    position: relative;
                    top: 2.5rem;
                    }

                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    }

                }
            .article__header-body {
                }
                .article__header-title {
                    margin-bottom: 2.5rem;
                    }
                .article__header-tag {
                    color: var(--brand-primary);
                    }
                .article__header-meta {
                    font-size: 1rem;
                    font-family: var(--brand-font);
                    color: #D8D8D8;
                    line-height: 1.25rem;
                    }

    /* Body */
    .article__body {
        @mixin container;
        @mixin container-gutters;
        margin-top: 7.5rem;
        }
        .article__content {
            padding-top: 2.5rem;

            & :matches(a, h2, h3, h4, h5, h6) {
                font-weight: 500;
                color: var(--brand-secondary);
                }
            }

    /* Share */
    .article__share {

        & a {
            color: #236192;

            &:hover {
                color: var(--brand-primary);
                }
            }

        }

@media (width >= 860px) {

    .article__header {
        display: flex;
        }
        .article__header-body {
            flex-basis: 40%;
            order: 99;
            padding: 2.5rem 0 2.5rem 2.5rem;
            }
        .article__header-media {
            flex-basis: 60%;

            & > * {
                height: calc(100% + 5rem);
                }
            }

    .article__body {
        display: flex;
        margin-top: 10rem;
        }
        .article__aside {
            flex: 0 1 210px;
            min-width: 150px;
            }
        .article__content {
            padding-top: 0;
            padding-left: 2.5rem;
            max-width: 660px;
            }

    .article__share {

        /* Stack social list vertically */
        & .social-list {
            display: inline-flex;
            flex-direction: column;
            width: 80px;

            & a {
                padding: .5rem;
                }
            }
            & .social-list__item:not(:first-child) {
                margin-top: 1.5rem;
                margin-left: 0;
                }

        }

}

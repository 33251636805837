/** ------------------------------
    Base
    ------------------------------ */
@define-mixin form-control {
    display: block;
    width: 100%;
    margin: var(--form-control-margin) 0;
    padding: 0 var(--form-control-padding);
    height: var(--form-control-height);
    line-height: var(--form-control-height);
    border: var(--form-control-border-width) solid var(--form-control-default-border-color);
    border-radius: var(--form-control-border-radius);
    background-color: var(--form-control-default-bg);
    font-family: var(--btn-font-family);
    color: var(--form-control-default-color);
    appearance: none; /* Remove iOS border-radius */

    &:focus {
        outline: none;
        border-color: var(--form-control-default-border-color-active);
        }

    &::placeholder {
        color: rgba(0, 0, 0, .3);
        }

    /* Fix padding of multi-line fields */
    &:matches(textarea, select[multiple]) {
        padding: var(--form-control-padding);
        height: auto;
        line-height: var(--base-line-height);
        }

    /* Colour pickers don't need to be 100% */
    &:matches([type="color"]) {
        width: calc(var(--form-control-height) + 1rem);
        }

    @media (width < 400px) {
        padding: 0 1rem;
        height: calc(var(--form-control-height) * .9);
        font-size: 90%;
        line-height: calc(var(--form-control-height) * .9);
        }
}

@define-mixin form-label {
    display: inline-block;
    font-family: var(--brand-font);
    font-weight: 500;
    color: var(--brand-secondary);
}

.form,
.form__group {
    @mixin base-margin;
    }
    .form__label {
        @mixin form-label;
        }
    .form__control {
        @mixin form-control;
        }


/** ------------------------------
    Custom controls
    ------------------------------ */
.custom-select-control {
    margin: var(--form-control-margin) 0;
    border: var(--form-control-border-width) solid var(--form-control-default-border-color);
    border-radius: var(--form-control-border-radius);
    background-color: var(--form-control-default-bg);
    background-image: url('../../img/inline/select-arrow.svg');
    background-position: calc(100% - 15px) 50%;
    background-repeat: no-repeat;
    background-size: 8px auto;

    &:focus-within {
        border-color: var(--form-control-default-border-color-active);
        }

    & > select {
        @mixin form-control;
        margin: 0;
        padding-right: 2em;
        border: 0;
        border-radius: 0;
        background-color: transparent;

        &:focus {
            outline: 0;
            }

        &::-ms-expand {
            display: none;
            }
        }
    }

.custom-file-control {
    margin: var(--form-control-margin) 0;
    }
    .custom-file-control__btn {
        position: relative;
        display: inline-block;

        & [type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            }
        }

/** ------------------------------
    Site Header
    ------------------------------ */
@custom-media --menu-collapsed (width < 900px);
@custom-media --menu-expanded (width >= 900px);

/* Utility class to apply consistent padding on the first content item */
.has-header-padding {
    padding-top: 100px !important;

    @media (height < 640px) {
        padding-top: 80px !important;
        }
    }

/* Hide header for JS users, loading animation fades it back in */
.js {

    & .site-header {
        opacity: 0;
        }

    }

.site-header {
    @mixin container-gutters;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    transition: background-color .4s ease;
    font-size: 1rem;
    color: #fff;

    & a {
        color: currentcolor;
        text-decoration: none;
        }
    }
    .site-header__container {
        display: flex;
        align-items: center;
        height: 100px;

        @media (height < 640px) {
            height: 80px;
            }
        }

    /* Brand */
    .site-header__brand-link {
        flex: 0 0 60vw;
        max-width: 180px;

        @media (--menu-expanded) {
            max-width: 150px;
            }

        @media (width > 1300px) {
            max-width: 210px;
            }

        @media (width < 480px) {

            & .brand-text {
                display: none;
                }

            }
        }

    /* Toggle */
    .site-header__menu-toggle {
        @mixin button-reset;
        margin-left: auto;
        width: 2.5rem;
        height: 2.5rem;
        color: inherit;

        & rect {
            transition: width .2s ease,
                        opacity .1s ease,
                        transform .3s ease;
            transform-origin: 50% 50%;
            }

        &:focus {
            outline: 0;
            }

        @media (hover: hover) {

            &:hover & rect {
                width: 100%;
                }

            }

        }

    /* Search */
    .site-header__search {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        z-index: 9999;
        background-color: rgba(32, 42, 68, .9);
        }
        .site-header__search-form {
            display: flex;
            height: 100px;
            background-color: #fff;

            @media (width < 600px) {
                height: 80px;
                }

            & > input {
                flex-grow: 1;
                padding: .5em .5em .5em 2em;
                height: 100%;
                border: 0;
                background: url('../../img/inline/search.svg') no-repeat .5em 50%;
                background-size: 3rem 3rem;
                font-family: var(--brand-font);
                font-size: 2em;
                font-weight: 300;

                &:focus {
                    outline: 0;
                    }

                @media (width < 600px) {
                    background-size: 2rem 2rem;
                    font-size: 1.5em;
                    }
                }

            & > button {
                @mixin button-reset;
                flex-shrink: 0;
                width: 100px;
                background-color: var(--brand-secondary);
                color: #fff;

                @media (width < 600px) {
                    width: 80px;
                    }

                & > svg {
                    width: 3rem;
                    height: 3rem;

                    @media (width < 600px) {
                        width: 2rem;
                        height: 2rem;
                        }
                    }

                &:focus {
                    outline: 0;
                    }

                &:hover {
                    background-color: var(--brand-primary);
                    }
                }
            }

    /* Subnav level 2 */
    .site-header__nav-lvl-2 {

        & a {
            display: flex;
            align-items: center;
            /* padding-left: 1rem; */

            & svg {
                margin-right: .25em;
                width: .75em;
                height: .75em;
                fill: currentcolor;
                }
            }

        }

@media (--menu-collapsed) {

    /* Menu */
    .site-header__menu-container {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        z-index: -1;
        }
        .site-header__menu-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100vh;
            background: var(--brand-secondary) url('../resources/assets/img/menu-bg.jpg') no-repeat 50% 50%;
            background-size: 1920px 1080px;
            z-index: -1;
            }
        .site-header__menu {
            position: relative;
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            @mixin container-gutters;
            padding-top: 100px;
            padding-bottom: 40px;
            color: #fff;

            & a {
                color: currentcolor;
                }

            /* Align all children to the container */
            & > * {
                @mixin container;
                width: 100%;
                }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                min-height: 100vh;
                background-color: rgba(20, 39, 70, .8);
                z-index: -1;
                }
            }
            .site-header__nav {
                font-family: var(--brand-font);
                font-size: 2.75rem;
                font-weight: 500;
                line-height: 1.1;

                @media (width < 1000px) {
                    font-size: calc(1rem + 3vw);
                    }

                & a {
                    @mixin link-hover;

                    &::after {
                        height: 3px;
                        }

                    }
                }
                .site-header__nav-item {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-bottom: 6vh;

                    &:first-child {
                        margin-top: 4vh;
                        }
                    }

            /* Subnav */
            .site-header__sub-nav-toggle {
                appearance: none;
                border: 0;
                background-color: transparent;
                color: currentcolor;
                transition: transform .2s ease;

                &[aria-expanded="true"] {
                    transform: rotate(-180deg);

                    & ~ .site-header__nav-lvl-1 {
                        display: block;
                        }
                    }
                }
            .site-header__nav-lvl-1 {
                display: none;
                margin-left: 1rem;
                width: 100%;
                font-size: .875em;
                font-weight: 300;

                & li {
                    margin-top: .5em;
                    }
                }

            /* Social */
            .site-header__menu-social {
                margin-top: auto;
                margin-bottom: 2rem;

                & a {
                    transition: color .3s ease;

                    &:hover {
                        color: var(--brand-primary);
                        }
                    }
                }

    /* Utilities */
    .site-header__utils {
        display: none;
        }

}

@media (--menu-expanded) {

    .site-header {
        font-size: 1.25rem;
        }

        /* Menu */
        :matches(.site-header__menu-toggle, .site-header__sub-nav-toggle) {
            display: none;
            }

        .site-header__menu-container {
            align-self: stretch;
            }
            .site-header__menu {
                margin-left: 2.5vw;
                height: 100%;
                font-family: var(--brand-font);

                /* Only show navigation */
                & > :not(.site-header__nav) {
                    display: none;
                    }
                }
                .site-header__nav {
                    display: flex;
                    height: 100%;

                    & > * {
                        margin-right: 2.5vw;
                        }

                    & .current-menu-item {
                        color: var(--brand-primary);
                        }
                    }
                    .site-header__nav-item {
                        position: relative;
                        display: flex;
                        align-items: center;
                        white-space: nowrap;

                        & > a {
                            @mixin link-hover;
                            }

                        & .site-header__nav-lvl-1 {
                            pointer-events: none;
                            }

                        &:matches(:hover, :focus-within) {

                            & .site-header__nav-lvl-1 {
                                pointer-events: auto;
                                transform: translateY(0);
                                opacity: 1;
                                z-index: 100;
                                }

                            }

                        }

                /* Subnav */
                .site-header__nav-lvl-1 {
                    overflow: hidden;
                    position: absolute;
                    top: calc(100%);
                    left: 0;
                    margin: 0 -1rem;
                    min-width: 200px;
                    white-space: nowrap;
                    box-shadow:
                        0 0.3px 0.3px rgba(0, 0, 0, .017),
                        0 0.8px 0.8px rgba(0, 0, 0, .025),
                        0 1.8px 1.8px rgba(0, 0, 0, .033),
                        0 6px 6px rgba(0, 0, 0, .05)
                    ;
                    background-color: #fff;
                    color: var(--brand-primary);
                    transform: translateY(-1rem);
                    opacity: 0;
                    z-index: -1;
                    transition:
                        transform .2s ease-in,
                        opacity .3s ease-in;

                    & a {
                        display: block;
                        padding: .667rem 1rem;
                        transition: background-color .3s ease-out;

                        &:matches(:hover, :focus) {
                            background-color: var(--brand-primary);
                            color: #fff;
                            }
                        }
                    }

        /* Utilities */
        .site-header__utils {
            flex-shrink: 0;
            margin-left: auto;
            font-family: var(--brand-font);
            }
            .site-header__utils-nav {
                display: flex;
                align-items: center;

                & > :not(:last-child) {
                    margin-right: 1em;
                    }

                & a {
                    @mixin link-hover;
                    }
                }
            .site-header__utils-search {
                @mixin button-reset;
                padding: .5rem;
                color: inherit;
                transition: background-color .3s ease;

                & svg {
                    width: 2rem;
                    height: 2rem;
                    }

                &:hover {
                    background-color: rgba(32, 42, 68, .1);
                    }
                }

    @media (width < 1200px) {

        .site-header {
            font-size: 1.125rem;
            }

            /* Make room for navigation */
            :matches(.site-header__nav-item:last-child, .site-header__utils) {
                display: none;
                }

    }

}


/** ------------------------------
    Modifiers
    ------------------------------ */

/* Scrolled state */
.site-header--scrolled {
    box-shadow:
        0 0.5px 1px rgba(0, 0, 0, .028),
        0 1.6px 3.4px rgba(0, 0, 0, .042),
        0 7px 15px rgba(0, 0, 0, .07)
    ;
    background-color: #fff;
    color: var(--brand-secondary);

    &:not(.site-header--menu-active) :matches(.brand-text__arcmont, .site-header__menu-toggle > svg, .site-header__utils-search svg) {
        fill: currentcolor;
        }

    & .site-header__utils {

        & a:hover {
            color: var(--brand-primary);
            }
        }
    }

/* Menu active state */
.site-header--menu-active {
    background-color: transparent;
    color: #fff;
    transition: none;

    & .site-header__menu-toggle {
        &:hover {
            color: var(--brand-primary);
            }

        & rect {
            width: 100%;

            &:nth-child(1) {
                transform: rotate(-45deg) translate(-1px, 8px);
                }

            &:nth-child(2) {
                opacity: 0;
                }

            &:nth-child(3) {
                transform: rotate(45deg) translate(0, -9px);
                }
            }
        }

    & .site-header__utils {

        & a:hover {
            color: var(--brand-primary);
            }

        }
    }


/** ------------------------------
    Admin bar
    ------------------------------ */
.admin-bar {

    & .site-header {
        top: 32px;

        @media (width < 782px) {
            top: 46px;
            }

        @media (width < 600px) {
            top: 0;
            }
        }

    }

/** ------------------------------
    Loading overlay
    ------------------------------ */
.loading-overlay {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    min-height: 100%;
    background-color: var(--brand-secondary);
    background-image: linear-gradient(315deg, #111927 0%, var(--brand-secondary) 74%);
    color: #fff;
    z-index: 1000;
    }
    .loading-overlay__icon {
        margin: auto;
        width: 5rem;
        height: auto;

        & path {
            fill: none;
            stroke-width: 2px;
            stroke: currentcolor;
            }

        }

.no-js {

    & .loading-overlay {
        display: none;
        }

}

/** ------------------------------
    Blockquote
    ------------------------------ */
.blockquote {
    margin: 4rem 0;
    padding-left: 3.75rem;
    background: url('../../img/inline/quote.svg') no-repeat 0 .33em;
    font-family: var(--brand-font);

    & p {
        font-weight: 500;
        font-size: 1.5rem;
        color: #236192;
        line-height: 1.6;
        }
    }
    .blockquote__cite {
        display: flex;
        flex-direction: column;
        margin-top: 2.5rem;
        font-style: normal;
        line-height: 1.25;
        }
        .blockquote__cite-name {
            margin-bottom: .5em;
            font-size: 1.5rem;
            font-weight: 600;
            color: var(--brand-secondary);
            }
        .blockquote__cite-role {
            font-size: 1rem;
            }

/** ------------------------------
    Reset
    ------------------------------ */

/* Enables fast-tap in iOS Safari when page is zoomed */
a,
button,
input,
textarea,
select,
details {
    touch-action: manipulation;
    }

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    }

fieldset {
    margin: 0;
    border-style: solid;
    border-width: 1px;
    }

textarea {
    resize: vertical;
    }

img,
svg {
    max-width: 100%;
    vertical-align: middle;
    }

img {
    height: auto;
    }

nav :matches(ul, ol) {
    @mixin list-reset;
    }

[hidden] {
    display: none !important;
    }

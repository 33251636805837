/** ------------------------------
    Sections
    ------------------------------ */
.section {
    /* overflow: hidden; */
    position: relative;
    margin: 5rem 0;

    @media (height > 640px) {
        margin: 7.5rem 0;
        }

    & :matches([id]) {
        scroll-margin: 200px 0 0;
        }
	}


/** ------------------------------
    Modifiers
    ------------------------------ */
.section--padded {
    margin: 0;
    padding: 5rem 0;

    @media (height > 640px) {
        padding: 7.5rem 0;
        }
    }

.section--overflow {
    margin-bottom: 0;
    padding-bottom: 0;

    & .img-overflow {
        position: relative;
        z-index: 50;
        margin: 5rem auto calc((9 / 16) / -2 * 100%);
        max-width: 1000px;
        }

    & + * {
        margin-top: 0;
        padding-top: 0;

        /* Push content down */
        & > :first-child::before {
            content: "";
            display: block;
            padding-top: calc((9 / 16) / 2 * 100%);
            margin-bottom: 5rem;
            }
        }

    }

.section--white {
	background-color: #fff;
	}

.section--dark {
    background-color: rgba(13, 24, 56, .95);
    color: #fff;

    & :matches(h1, h2, h3, h4, h5, h6) {
        color: var(--brand-primary);
        }

    & .lead {
        color: currentcolor;
        }
    }

.section--light {
    background-color: var(--brand-light);
    }

/** ------------------------------
    Content filter
    ------------------------------ */
.content-filter {
    }

    /* Search */
    .content-filter__search {

        @media (width >= 560px) {
            display: flex;
            align-items: center;
            }

        }
        .content-filter__search-control {
            margin: 1rem 0;

            @media (width >= 560px) {
                flex: 1 1 350px;
                margin-right: auto;
                max-width: 500px;
                }
            }
        .content-filter__search-results {
            margin: 1rem 0;
            font-family: var(--brand-font);
            font-size: 1rem;
            color: var(--brand-secondary);
            line-height: 1.25;

            @media (width >= 560px) {
                flex-shrink: 0;
                margin-left: 1rem;
                }

            @media (width >= 900px) {
                font-size: 1em;
                }
            }

    /* Options */
    .content-filter__options {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -.5rem;
        }
        .content-filter__options-item {
            flex: 1 0 200px;
            padding: .5rem;

            @media (width <= 800px) {
                font-size: 1rem;
                }
            }

    /* No results */
    .content-filter__no-results {
        display: none;
        margin-top: 2.5rem;
        }

/** ------------------------------
    Box sizing
    ------------------------------ */
html {
    box-sizing: border-box;
    }

*,
*::before,
*::after {
    box-sizing: inherit;
    }


/** ------------------------------
    Containers
    ------------------------------ */
main {
    overflow-x: hidden;
    }

@define-mixin container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: var(--container-width);
}

@define-mixin container-gutters {
    padding: 0 calc(var(--container-gutter) / 2);

    @media (width > 1200px) {
        padding: 0 var(--container-gutter);
        }
}

.container {
    @mixin container;
    @mixin container-gutters;
    }

.container--md {
    max-width: calc(var(--container-width) * .8);
    }

.container--sm {
    max-width: calc(var(--container-width) * .6);
    }

.container--fluid {
    max-width: none;
    }

.is-contained {
    @mixin container;
    }


/** ------------------------------
    Parallax
    ------------------------------ */
.parallax {
    position: relative;
    }
    .parallax__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../resources/assets/img/parallax-bg.png') no-repeat calc(100% + (280px - 15vw)) 0;
        background-size: 590vh auto;

        @media (width > 1700px) {
            background-position: 100% 0;
            }

        @media (width < 768px) {
            display: none;
            }
        }

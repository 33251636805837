/** ------------------------------
    Page Hero
    ------------------------------ */
.page-hero {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    color: #fff;
    }
    .page-hero__media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--brand-secondary);
        z-index: -1;

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .5;
            }

        & > video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            transform: translate3d(-50%, -50%, 0);
            }
        }

    .page-hero__body {
        position: relative;
        @mixin container-gutters;
        flex-grow: 1;
        }
        .page-hero__title {
            margin-top: 6vh;
            color: currentcolor;
            font-size: 3.5rem;
            line-height: 1.285;
            text-shadow: 4px 4px 10px rgba(0, 0, 0, .2);

            /* Reponsive font-size */
            @media (width < 920px) {
                font-size: calc(1rem + 3.5vw)
                }

            /* Account for parallax background */
            @media (width >= 768px) {
                width: calc(100% - 22vw);
                max-width: 960px;
                }

            /* Align content to 'Arcmont' text */
            @media (width >= 960px) {
                margin-left: 55px;
                }

            @media (width >= 1300px) {
                margin-left: 65px;
                }
            }
    .page-hero__scroll {
        display: block;
        position: relative;
        width: 40px;
        border-bottom: 2px solid var(--brand-primary);
        margin: 4rem auto -8rem 400px;
        padding: 1rem 0;
        text-align: center;

        @media (width < 768px) {
            margin: 3rem auto 0;
            }

        @media (height >= 700px) {
            margin-top: 8rem;
            }

        &::before {
            content: "Scroll";
            position: absolute;
            top: -1em;
            left: 50%;
            margin-left: 0.2em;
            transform: translateX(-50%);
            font-size: 0.8rem;
            font-weight: 700;
            letter-spacing: .3em;
            text-transform: uppercase;
            }

        & > svg {
            width: 10px;
            height: 43px;
            animation: scrollBounce 2s 5s 4;
            transform-origin: 50% 0;
            fill: currentcolor;
            }
        }

@keyframes scrollBounce {

    0%, 100% {
        transform: translateY(0);
        }

    50% {
        transform: translateY(-6px);
        }
}


/** ------------------------------
    Contact
    ------------------------------ */
@custom-media --contact-stacked (width < 900px);
@custom-media --contact-cover (width >= 900px);

.contact {
    margin: 0;
    padding: 5rem 0;

    /* Remove padding from .section--padded on when stacked */
    @media (--contact-stacked) {
        padding: 0;
        }
    }
    .contact__panel {
        @mixin container-gutters;
        padding-top: 5rem;
        padding-bottom: 5rem;
        background-color: var(--brand-secondary);
        color: #fff;
        }

        /* Tabs */
        .contact__tabs {
            margin-top: 3rem;
            }
            .contact__tabs-menu {

                @media (--contact-stacked) {
                    overflow-x: auto;
                    margin-bottom: 2rem;
                    padding-bottom: .5rem;
                    }

                & a {
                    color: currentcolor;
                    text-decoration: none;
                    }
                }
                .contact__tabs-list {
                    display: flex;
                    font-size: .875rem;
                    font-weight: 700;
                    letter-spacing: .3em;
                    text-transform: uppercase;

                    & > li:not(:first-child) {
                        margin-left: 10vw;

                        @media (width < 360px) {
                            margin-left: 1rem;
                            }
                        }

                    & a {
                        position: relative;
                        display: inline-block;
                        opacity: .4;
                        transition: opacity .3s ease;

                        &::after {
                            content: "";
                            position: absolute;
                            bottom: -.25em;
                            right: 0;
                            width: 0%;
                            height: 2px;
                            background-color: currentcolor;
                            transition: width .3s ease;
                            }

                        &:hover {
                            opacity: 1;
                            }

                        &[aria-selected] {
                            opacity: 1;

                            &::after {
                                left: 0;
                                width: 100%;
                                }
                            }
                        }
                    }
            .contact__tabs-section {
                & a {
                    text-decoration: none;
                    color: var(--brand-primary);
                    border-bottom: 1px solid currentcolor;
                    transition: color .3s ease;

                    &:hover {
                        color: #fff;
                        }
                    }
                }
                .contact__tabs-title {
                    margin-bottom: .25rem;
                    }


    /* Menu */
    .contact__map-container {
        height: 70vh;
        max-height: 600px;
        background-color: var(--brand-light);

        & > div {
            width: 100%;
            height: 100%;
            }
        }

@media (width >= 640px) {

    .contact__tabs {
        display: flex;
        }
        .contact__tabs-list {
            flex-direction: column;
            align-items: flex-start;
            margin-right: 3rem;

            & > li:not(:first-child) {
                margin-top: 2rem;
                margin-left: 0;
                }
            }

}

@media (--contact-cover) {

    .contact {
        }
        .contact__body {
            @mixin container;
            @mixin container-gutters;
            position: relative;
            z-index: 5;
            pointer-events: none; /* Allow interaction with map */

            & > * {
                pointer-events: auto; /* Allow interaction on children */
                }
            }
            .contact__panel {
                padding: 5rem 2.5rem;
                width: 50%;
                min-height: 580px;
                min-width: 640px;
                max-width: 680px;
                }
        .contact__map-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: none;
            z-index: 1;
            }

}

/** ------------------------------
    Contact
    ------------------------------ */
.contact-grid {

    @media (width >= 800px) {
        display: flex;
        justify-content: space-between;
        }

    }
    .contact-grid__form {
        flex: 0 1 520px;

        @media (width >= 800px) {
            margin-right: 2rem;
            }
        }
    .contact-grid__sidebar {
        margin-top: 2rem;
        }


/* Form */
.frm_forms {

    & fieldset {
        border: 0;
        padding: 0;
        margin: 0;
        }

    }
    .frm_form_field {

        & label {
            @mixin form-label;
            }

        & input:not([type="checkbox"], [type="radio"]) {
            @mixin form-control;
            }

        & textarea {
            @mixin form-control;
            padding: var(--form-control-padding);
            height: auto;
            line-height: var(--base-line-height);
            }

        & .frm_required {
            color: red;
            }

        }
    .frm_button_submit {
        @mixin button;
        }
    .frm_error {
        margin-bottom: .5rem;
        font-size: .875em;
        color: red;
        }
    :matches(.frm_screen_reader, .frm_verify) {
        @mixin visually-hidden;
        }

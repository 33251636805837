/** ------------------------------
    Brand colours
    ------------------------------ */
.fg-brand-primary {
    color: var(--brand-primary);
    }

.fg-brand-secondary {
    color: var(--brand-secondary);
    }

.fg-brand-light {
    color: var(--brand-light);
    }

.fg-brand-dark {
    color: var(--brand-dark);
    }

.bg-brand-primary {
    background-color: var(--brand-primary);
    }

.bg-brand-secondary {
    background-color: var(--brand-secondary);
    }

.bg-brand-light {
    background-color: var(--brand-light);
    }

.bg-brand-dark {
    background-color: var(--brand-dark);
    }


/** ------------------------------
    Images
    ------------------------------ */
.img-branded {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        width: 2.5rem;
        height: 29px;
        background: url('../../img/inline/brandmark-white.svg') no-repeat;
        background-size: 100% auto;
        z-index: 10;
        }
    }

.img-spaced {
    display: block;
    margin: 5rem auto;
    }


/** ------------------------------
    Alignments
    ------------------------------ */
@define-mixin center-block {
    margin-left: auto;
    margin-right: auto;
}

.center-block {
    @mixin center-block;
    }

@define-mixin align $alignment, $force: false {
    @if $force == true {
        text-align: $alignment !important;
    } @else {
        text-align: $alignment;
    }
}

@define-mixin pull $alignment, $force: false {
    @if $force == true {
        float: $alignment !important;
    } @else {
        float: $alignment;
    }
}

.align-center {
    @mixin align center, true;
    }

.align-left {
    @mixin align left, true;
    }

.align-right {
    @mixin align right, true;
    }

.pull-left {
    @mixin pull left, true;
    }

.pull-right {
    @mixin pull right, true;
    }

.pull-none {
    @mixin pull none, true;
    }


/** ------------------------------
    Layout
    ------------------------------ */
.no-margin {
    margin: 0 !important;
    }

.no-padding {
    padding: 0 !important;
    }

.width-capped {
    max-width:  760px;
    }


/** ------------------------------
    Visibility
    ------------------------------ */
.hidden {
    display: none;
    }

@define-mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.visually-hidden {
    @mixin visually-hidden;
    }


/** ------------------------------
    Skip link
    ------------------------------ */
.skip-link {
    @mixin visually-hidden;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    z-index: 999999;

    &:focus {
        padding: 1em;
        margin: 0;
        width: auto;
        height: auto;
        clip: auto;
        }
    }


/** ------------------------------
    Lazyload
    ------------------------------ */
:matches(.lazyload, .lazyloading) {
    opacity: 0;

    &[data-expand] {
        transform: translateY(100px);
        }
    }

.lazyloaded {
    opacity: 1;
    transition:
        opacity .8s easeInQuart,
        transform 1s easeOutQuint;

    &[data-expand] {
        transform: translateY(0);
        }
    }


/** ------------------------------
    Infinite scroll
    ------------------------------ */
.infinite-scroll {
    margin-top: 5rem;
    text-align: center;
    }

/** ------------------------------
    Page Header
    ------------------------------ */
.page-header {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--brand-secondary);
    color: #fff;

    @media (height >= 640px) {
        min-height: 450px;
        }

    & a {
        color: var(--brand-primary);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            }
        }
    }
    .page-header__cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        & > svg {
            opacity: .2;
            }

        @media (width > 768px) {

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: url('../resources/assets/img/header-bg.png') no-repeat 100% 0;
                background-size: contain;
                z-index: 2;
                }

            }
        }
    .page-header__body {
        @mixin container;
        @mixin container-gutters;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        position: relative;
        z-index: 5;

        & > :last-child {
            margin-bottom: 0;
            }

        @media (height > 640px) {
            padding-top: 0;
            }
        }

/** ------------------------------
    Media block
    ------------------------------ */
@media (width < 900px) {

	.media-block__media {
		display: none;
		}

}

@media (width >= 900px) {

	.media-block {
		display: flex;
        }
		.media-block__media {
            flex: 0 1 410px;
            margin-left: 2rem;
			}
		.media-block__body {
			flex-grow: 1;

			& > p {
				max-width: 575px;
				}
            }

}


/** ------------------------------
    Panel
    ------------------------------ */
.panel {
    padding: 1.5rem;
    background-color: var(--brand-light);

    & a {
        color: var(--brand-primary);

        &:hover {
            text-decoration: none;
            }
        }
    }

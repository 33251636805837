/** ------------------------------
    Responsive Media
    ------------------------------ */
.responsive-media {
    position: relative;

    &::before {
        content: "";
        display: block;
        padding-bottom: calc(9 / 16 * 100%);
        }

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        }
    }


/** ------------------------------
    Modifiers
    ------------------------------ */
.responsive-media--4x3 {

    &::before {
        padding-bottom: calc(3 / 4 * 100%);
        }

    }

.responsive-media--4x5 {

    &::before {
        padding-bottom: calc(5 / 4 * 100%);
        }

    }

.responsive-media--1x1 {

    &::before {
        padding-bottom: 100%;
        }

    }

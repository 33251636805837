/** ------------------------------
    Tabbed Nav
    ------------------------------ */
.tabbed-nav {
    @mixin list-reset;
    display: flex;
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .3em;
    text-transform: uppercase;

    & > li:not(:first-child) {
        margin-left: 2rem;

        @media (width < 360px) {
            margin-left: 1rem;
            }
        }

    & a {
        display: inline-block;
        color: var(--brand-secondary);
        opacity: .4;
        transition: opacity .3s ease;
        text-decoration: none;
        @mixin link-hover;

        &:hover {
            opacity: 1;
            }

        &::after {
            height: 2px;
            }

        &[aria-selected] {
            opacity: 1;

            &::after {
                left: 0;
                width: 100%;
                }
            }
        }
    }

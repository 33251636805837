/** ------------------------------
    Media object
    ------------------------------ */
.media-object {
    display: flex;
    }
    .media-object__media {
        flex-shrink: 0;
        }
    .media-object__body {
        flex-grow: 1;
        padding-top: .25em;
        padding-left: 1rem;
        font-family: var(--brand-font);
        font-size: 1rem;
        line-height: 1.25;

        & > :last-child {
            margin-bottom: 0;
            }
        }
        .media-object__title {
            margin-bottom: .25em;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.2;
            color: var(--brand-secondary);
            }

/** ------------------------------
    About
    ------------------------------ */
.values-grid {

    & + * {
        margin-top: 4rem;
        }

    }
    .values-grid__item {
        margin-top: 4rem;

        & > :last-child {
            margin-bottom: 0;
            }
        }
        .values-grid__item-title {
            margin-bottom: .5rem;
            color: var(--brand-primary);
            }

@media (width >= 620px) {

    .values-grid {
        display: flex;
        flex-wrap: wrap;
        @mixin grid-container;
        }
        .values-grid__item {
            @mixin grid-item;
            flex-basis: 50%;
            }

}

@media (width >= 1000px) {

    .values-grid__item {
        flex-basis: 33.333%;
        }

}

/** ------------------------------
    Base
    ------------------------------ */
@define-mixin button {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: stretch;
    padding: 0 var(--btn-padding);
    height: var(--btn-height);
    line-height: var(--btn-height);
    border: 1px solid var(--btn-default-border-color);
    border-radius: var(--btn-border-radius);
    background-color: var(--btn-default-bg);
    font-family: var(--btn-font-family);
    font-weight: var(--btn-font-weight);
    font-size: var(--btn-font-size);;
    color: var(--btn-default-color);
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    transition: color .2s ease;

    &:focus {
        outline: 0;
        }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 130%;
        height: 100%;
        background-color: var(--brand-primary);
        transform: translateX(-110%) skew(30deg, 0);
        transition: all .3s ease;
        will-change: transform;
        z-index: 1;
        }

    &:hover {
        border-color: var(--brand-primary);
        color: #fff;

        & .btn__icon {
            border-color: rgba(255, 255, 255, .3);
            color: var(--brand-fff);;
            }

        &::after {
            transform: translateX(-15%) skew(30deg, 0);
            }
        }

    & > span {
        position: relative;
        z-index: 2;

        &:not(.btn__icon) {
            flex-grow: 1;
            text-align: center;
            }
        }

}

.btn {
    @mixin button;
    }
    .btn__icon {
        display: flex;
        flex-grow: 0;
        margin: 0 calc(var(--btn-padding) * -1) 0 var(--btn-padding);
        width: var(--btn-height);
        border-left: 1px solid var(--btn-default-border-color);

        &:first-child {
            margin: 0 var(--btn-padding) 0 calc(var(--btn-padding) * -1);
            border-left: 0;
            border-right: 1px solid var(--btn-default-border-color);
            }

        & > svg {
            margin: auto;
            width: 1.25rem;
            height: 1.25rem;
            }
        }

@media (width < 400px) {

    .btn {
        padding: 0 1rem;
        height: calc(var(--btn-height) * .9);
        line-height: calc(var(--btn-height) * .9);
        font-size: calc(var(--btn-font-size) * .9);
        }
        .btn__icon {
            margin: 0 -1rem 0 1rem;
            width: calc(var(--btn-height) * .9);

            &:first-child {
                margin: 0 1rem 0 -1rem;
                }
            }

}


/** ------------------------------
    Modifiers
    ------------------------------ */
.btn--block {
    width: 100%;
    }

.btn--ghost {
    background-color: transparent;
    color: inherit;
    }


/** ------------------------------
    Wrappers
    ------------------------------ */
.btn-cta {
    margin-top: 2.5rem;
    }

.btn-group {
    display: flex;

    & > :not(:last-child) {
        margin-right: 1.25rem;
        }
    }

/** ------------------------------
    Counted container
    ------------------------------ */
body {
    counter-reset: section;
    }

.counted {
    counter-increment: section;

    & + .counted {
        margin-top: 5rem;

        @media (height > 640px) {
            margin-top: 7.5rem;
            }
        }
    }

@media (width >= 900px) {

    .counted {
        position: relative;
        padding-left: 5rem;

        &::before {
            content: "0" counter(section);
            position: absolute;
            top: -.125em;
            left: 0;
            font-weight: 300;
            font-size: 3rem;
            line-height: 1;
            text-align: right;
            }
        }

}

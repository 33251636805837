/** ------------------------------
    Stat
    ------------------------------ */
.stat {
    text-align: center;
    }
    .stat__figure {
        font-family: var(--brand-font);
        font-size: 4rem;
        line-height: 1.2;
        white-space: nowrap;

        @media (width < 500px) {
            font-size: calc(9.5vw + 1rem);
            }
        }
    .stat__description {
        margin: 0;
        color: var(--brand-primary);
        line-height: 1.3;
        white-space: nowrap;

        & > span {
            font-weight: 400;
            }
        }

/* Animation */
.js {

    & .stat {
        opacity: 0;
        transform: translateY(40px);
        transition: opacity .5s ease-out,
                    transform 1.2s ease-out;

        &.is-inview {
            opacity: 1;
            transform: translateY(0);
            }
        }

    }


/** ------------------------------
    Grid
    ------------------------------ */
.stat-grid {
    @mixin grid-container;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 0;
    }
    .stat-grid__item {
        @mixin grid-item;
        padding-top: 2rem;
        padding-bottom: 2rem;
        flex: 1;

        @media (width <= 440px) {
            flex-basis: 100%;
            }
        }

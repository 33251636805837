/** ------------------------------
    Site Footer
    ------------------------------ */
.site-footer {
    background-color: var(--brand-light);
    border-top: 3px solid var(--brand-primary);
    font-size: 1rem;
    color: #666;
    }
    .site-footer__brand {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 0;
        background-color: var(--brand-secondary);

        & a {
            color: #fff;
            transition: color .3s ease;

            &:hover {
                color: var(--brand-primary);
                }
        }

        & > svg {
            width: 45vw;
            max-width: 210px;
            }
    }
    .site-footer__body {
        padding: 3rem 0;
        font-size: .875rem;

        & p {
            line-height: 1.75;
            }
        }

    /* Grid */
    .site-footer__grid-item:not(:first-child) {
        margin-top: 3rem;
        }

    /* Menu */
    .site-footer__menu {
        font-size: 1.25rem;
        font-family: var(--heading-font-family);
        font-weight: 600;

        & a {
            color: var(--brand-secondary);
            text-decoration: none;
            transition: color ease-out 150ms;

            &:hover {
                color: var(--brand-primary);
                }
            }

        & li:not(:first-child) {
            margin-top: 1rem;
            }
        }

    /* Legal */
    .site-footer__legal {
        background-color: #fff;
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-top: 1px solid var(--brand-secondary);
        }

@media (width >= 640px) {

    .site-footer__grid {
        @mixin grid-container;
        display: flex;
        flex-wrap: nowrap;
        }
        .site-footer__grid-item {
            @mixin grid-item;
            flex-grow: 1;
            margin: 0 !important;

            &:first-child {
                flex-basis: 30%;
                flex-shrink: 0;
                flex-grow: 0;
                }
            }

}

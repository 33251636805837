/** ------------------------------
    Base
    ------------------------------ */
html {
    font: var(--base-font-weight) var(--base-font-size)/var(--base-line-height) var(--base-font-family);
    color: var(--base-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

body {
    font-size: 1.25em;
    }

::selection {
    background-color: var(--brand-primary);
    color: white;
    }


/** ------------------------------
    Links
    ------------------------------ */
a {
    color: currentColor;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;

    &:hover {
        color: var(--brand-primary);
        }
    }

/** ------------------------------
    Headings
    ------------------------------ */
h1, h2, h3, h4, h5, h6,
.hd-xl, .hd-lg, .hd-md, .hd-bd, .hd-sm, .hd-xs {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    line-height: 1.25;
    }

.hd-xl {
    font-size: 4rem; /* 64px */
    line-height: 1.09; /* 70px */

    @media (width < 900px) {
        font-size: calc(1rem + 5.333vw);
        }
    }

.hd-lg {
    font-size: 3rem; /* 48px */
    line-height: 1; /* 48px */

    @media (width < 600px) {
        font-size: calc(1rem + 5vw);
        }
    }

.hd-md {
    font-size: 1.5rem; /* 24px */
    line-height: 1; /* 24px */
    font-weight: 500;
    }

.hd-bd {
    font-size: 1em; /* 16px */
    line-height: inherit;
    font-weight: 500;
    }

.hd-sm {
    font-family: var(--font-sans);
    font-size: .85rem;
    line-height: 1rem;
    font-weight: 600;
    }

.hd-xs {
    font-family: var(--font-sans);
    font-size: .75rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .3em;
    line-height: 1.1667;
    }

.lead {
    font-size: 1.75rem;
    font-family: var(--heading-font-family);
    font-weight: 500;
    color: var(--brand-secondary);
    line-height: 1.25;
    letter-spacing: -.5px;

    @media (width < 600px) {
        font-size: calc(1.25rem + 1vw);
        }
    }

.hd-branded {
    margin-bottom: 2.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid currentcolor;
    color: var(--brand-primary);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: .15em;
    text-transform: uppercase;
    }


/** ------------------------------
    Content
    ------------------------------ */
p,
ol,
ul {
    @mixin base-margin;
    }

.small {
    font-size: 1rem;
    }

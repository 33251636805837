/** ------------------------------
    Team card
    ------------------------------ */
.team-card {
    position: relative;
    color: #fff;

    & a {
        color: currentcolor;
        text-decoration: none;
        }

    &:matches(:hover, :focus-within) {

        /* Remove image filters */
        & img {
            filter: saturate(1) contrast(100%);
            opacity: 1;
            }

        /* Fade in email */
        @media (hover: hover) {

            & .team-card__body {
                padding-bottom: 2.5rem;
                background-image: linear-gradient(to top, rgba(0, 0, 0, .4) 10%, transparent 30%);
                }
                & .team-card__email {
                    opacity: 1;
                    }

            }
        }
    }
    .team-card__media {
        background-color: #236192;

        & img {
            filter: saturate(0) contrast(125%);
            opacity: .6;
            transition: all .3s ease;
            }
        }
    .team-card__body {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        top: 0;
        left: 0;
        padding: 1rem;
        width: 100%;
        height: 100%;
        transition: padding-bottom .3s ease;
        will-change: padding-bottom;

        & > * {
            margin: 0;
            transition: all .3s ease;
            }

        & > :not(.team-card__title) {
            padding: .5em 0;
            font-size: .75rem;
            line-height: 1.1667;
            }
        }
        .team-card__title {
            font-size: 1.5rem;
            line-height: 1;

            & a {
                /* Pseduo link covering entire card */
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 999;
                    }
                }
            }
        .team-card__role {
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: .33em;
            }
        .team-card__email {
            padding: .5em 0;
            letter-spacing: 1px;

            @media (hover: hover) {
                position: absolute;
                bottom: 1rem;
                opacity: 0;
                }
            }


/** ------------------------------
    Team grid
    ------------------------------ */
.team-grid {
    }
    .team-grid__item {
        margin-top: 30px;
        width: 100%;
        }
    .team-grid__item-title {
        margin-top: 3rem;
        width: 100%;
        }

@media (width >= 530px) {

    .team-grid {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
        }
        .team-grid__item {
            padding-left: 15px;
            padding-right: 15px;
            width: 50%;
            }
        .team-grid__item-title {
            padding-left: 15px;
            padding-right: 15px;
            }

}

@media (width >= 768px) {

    .team-grid__item {
        width: 33.3333%;
        }

}

@media (width >= 1100px) {

    .team-grid {
        margin-left: -10px;
        margin-right: -10px;
        }
        .team-grid__item {
            margin-top: 20px;
            padding-left: 10px;
            padding-right: 10px;
            width: 25%;
            }
            .team-grid__item-title {
                padding-left: 10px;
                padding-right: 10px;
                }

}

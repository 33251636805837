/** ------------------------------
    Team profile
    ------------------------------ */
.team-profile {
    }

    /* Header */
    .team-profile__header-wrapper {
        background-color: var(--brand-secondary);
        color: #fff;

        & a {
            color: var(--brand-primary);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                }
            }
        }
        .team-profile__header {
            @mixin container;
            @mixin container-gutters;
            padding-top: 2.5rem;
            }
            .team-profile__header-media {
                position: relative;
                top: 2.5rem;
                }
            .team-profile__header-body {
                }
                .team-profile__header-title {
                    margin-bottom: .75rem;
                    }
                .team-profile__header-role {
                    margin-bottom: .5rem;
                    font-weight: 400;
                    }
                .team-profile__header-description {
                    margin-bottom: 4rem;
                    opacity: .6;
                    }

    /* Body */
    .team-profile__body {
        margin: 5rem 0;
        }

    /* Nav */
    .team-profile__nav {
        margin: 2.5rem 0 5rem;

        @media (width >= 650px) {
            display: flex;
            justify-content: space-between;
            }

        /* Hide back button for small devices */
        @media (width < 650px) {
            & > .btn {
                display: none;
                }
            }
        }

@media (width >= 600px) {

    .team-profile__header {
        display: flex;
        min-height: 350px;
        }
        .team-profile__header-body {
            flex-basis: 60%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 2.5rem 2.5rem 2.5rem 0;
            }
            .team-profile__header-email {
                margin: auto 0 0;
                }
        .team-profile__header-media {
            position: static;
            flex-basis: 40%;
            margin-bottom: -5rem;

            & > img {
                @mixin img-fit;
                object-position: 50% 0;
                }
            }

    .team-profile__body {
        margin-top: 7.5rem;
        }

}

@media (width >= 800px) {

    .team-profile__header-media {
        margin-bottom: -20vw;
        }

    .team-profile__body {
        margin-top: 2.5rem;
        min-height: calc(20vw - 2.5rem);
        width: calc(60% - var(--grid-gutter));
        }

}
